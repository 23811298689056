.full-layout {
  background-color: #f5f5f5 !important;
  height: 100vh;
}

.ck-login-container{
  position:relative;
  height:100%;
  padding-bottom:30px;
  -webkit-flex:1;
  -ms-flex:1;
  flex:1;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:-ms-flex;
  display:flex;
  -webkit-flex-direction:row;
  -ms-flex-direction:row;
  flex-direction:row;
  -webkit-flex-wrap:wrap;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-align-items:center;
  -ms-align-items:center;
  align-items:center;
  -webkit-justify-content:center;
  -ms-justify-content:center;
  justify-content:center
}

.ck-app-login-wrap{
  height:100%;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:-ms-flex;
  display:flex;
  -webkit-flex-direction:column;
  -ms-flex-direction:column;
  flex-direction:column;
  -webkit-flex-wrap:nowrap;
  -ms-flex-wrap:nowrap;
  flex-wrap:nowrap;
  -webkit-justify-content:center;
  -ms-justify-content:center;
  justify-content:center;
  overflow-x:hidden;
}
@media screen and (max-width:575px){
  .ck-app-login-wrap{
      padding-top:20px;
      -webkit-justify-content:flex-start;
      -ms-justify-content:flex-start;
      justify-content:flex-start
  }
}
.ck-app-login-container{
  position:relative;
  max-width:680px;
  width:94%;
  margin:0 auto
}
@media screen and (max-width:575px){
  .ck-app-login-container{
      padding-bottom:20px 
  }
}
.ck-app-login-main-content{
  display:-webkit-flex;
  display:-ms-flexbox;
  display:-ms-flex;
  display:flex;
  -webkit-flex-direction:row;
  -ms-flex-direction:row;
  flex-direction:row;
  -webkit-flex-wrap:wrap;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  background-color:#ffffff;
  -webkit-box-shadow:0 0 5px 5px rgba(0,0,0,0.03);
  -moz-box-shadow:0 0 5px 5px rgba(0,0,0,0.03);
  box-shadow:0 0 5px 5px rgba(0,0,0,0.03);
  -webkit-border-radius:12px;
  -moz-border-radius:12px;
  border-radius:12px;
  font-size:14px;
  overflow:hidden
}
.ck-app-login-content{
  padding:35px 35px 20px;
  width:60%
}
.ck-app-login-content .ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 7.9px 11px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all .3s;
}
.ck-app-login-content .ck-btn{
  padding:6px 35px !important;
  height:auto;
  border-radius: 6px
}
@media screen and (max-width:575px){
  .ck-app-login-content{
      width:100%;
      padding:20px 20px 10px
  }
}
.ck-app-logo-content{
  color:#ffffff;
  padding:35px 35px 20px;
  width:40%;
  position:relative;
  overflow:hidden;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:-ms-flex;
  display:flex;
  -webkit-flex-direction:column;
  -ms-flex-direction:column;
  flex-direction:column;
  -webkit-flex-wrap:nowrap;
  -ms-flex-wrap:nowrap;
  flex-wrap:nowrap
}
.ck-app-logo-content>*{
  position:relative;
  z-index:2
}
.ck-app-logo-content h1{
  color:#ffffff
}
@media screen and (max-width:575px){
  .ck-app-logo-content{
      width:100%;
      padding:20px 20px 10px
  }
}
.ck-app-logo-content-bg{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index:1
}
.ck-app-logo-content-bg:before{
  content:"";
  position:absolute;
  left:0;
  top:0;
  z-index:1;
  right:0;
  bottom:0;
  background-color: rgb(5 5 5 / 60%);
}
.ck-app-logo-content-bg img{
  width:100%;
  height:100%;
  object-fit: cover;
}
.ck-app-logo-wid{
  margin-bottom:auto;
}
.ant-form-item-control-input {
  min-height:40px;
}
.ck-app-login-content .ant-input-password {
  min-height:40px;
}

.ck-app-login-footer{
  margin-top: 50px;
  font-size: 12px;
  margin-bottom: -5px;
}